::selection {
	color: #fff;
	background: #f69221;
}
::-moz-selection {
	color: #fff;
	background: #f69221;
}
* {
	box-sizing: border-box;
}
body, html {
	background-color: var(--main-bg) !important;
}
.modal-open {
	overflow: hidden;
}
a {
	text-decoration: none;
}
select, input {
	background-color: var(--card-bg)
}
.text-eag {
	color: #f69221 !important;
}
select option {
	background-color: white;
}
.header {
	background-color: var(--card-bg);
	width: 100%;
}
.logo {
	width: 192px;
}
.logo {
	width: 250px;
}
.row [class^=col] {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
input::placeholder, textarea::placeholder {
	font-size: 14px;
	color: var(--secondary-text);
}
input:focus, textarea:focus, select:focus {
	outline: none !important;
}
button:disabled {
	background-color: #C4C4C4;
	border-color: #C4C4C4;
	user-select: none;
	cursor: not-allowed;
}
button:disabled:hover {
	background-color: #C4C4C4;
	border-color: #C4C4C4;
}
// Text
.title {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 29px;
}
.title-2 {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: lighter;
	font-size: 24px;
	line-height: 29px;
	// color: var(--main-text);
}
.title-3 {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: lighter;
	font-size: 20px;
	line-height: 24px;
	// color: var(--main-text);
}
.subtitle {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	// color: var(--main-text);
}
.heading {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 18px;
	// color: var(--main-text);
}
.subheading {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: lighter;
	font-size: 15px;
	line-height: 18px;
	// color: var(--main-text);
}
.text {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: lighter;
	font-size: 14px;
	line-height: 17px;
	// color: var(--main-text)
}
.text-signup {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: lighter;
	font-size: 10px;
	line-height: 17px;
	// color: var(--main-text)
}
.special-text {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 17px;
	letter-spacing: 0.1em;
	// color: var(--secondary-text);
	text-transform: uppercase;
}
.rating-text {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 17px;
	color: #44C568;
}
.error-message {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: var(--error);
	margin: 5px;
}
.text-white {
	color: white !important;

	path {
		fill: white !important;
	}
}
// Modifiers
.unpadify-x {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.mt-0 {
	margin-top: 0 !important;
}
.mt-10 {
	margin-top: 10px !important;
}
.mt-15 {
	margin-top: 15px !important;
}
.mt-20 {
	margin-top: 20px;
}
.mt-30 {
	margin-top: 30px;
}
.mt-60 {
	margin-top: 60px;
}
.mt-70 {
	margin-top: 70px;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.mb-5 {
	margin-bottom: 5px !important;
}
.mb-15 {
	margin-bottom: 15px !important;
}
.mb-30 {
	margin-bottom: 30px !important;
}
.mr-5 {
	margin-right: 5px !important
}
.mr-10 {
	margin-right: 10px !important
}
.mr-15 {
	margin-right: 15px !important
}
.mr-20 {
	margin-right: 20px !important
}
.mr-30 {
	margin-right: 30px !important
}
.ml-5 {
	margin-left: 5px !important
}
.ml-10 {
	margin-left: 10px !important
}
.ml-20 {
	margin-left: 20px !important
}
.my-10 {
	margin-top: 10px !important;
	margin-bottom: 10px !important;
}
.my-15 {
	margin-top: 15px !important;
	margin-bottom: 15px !important;
}
.my-20 {
	margin-top: 20px !important;
	margin-bottom: 20px !important;
}
.my-30 {
	margin-top: 30px !important;
	margin-bottom: 30px !important;
}
.mx-5 {
	margin-right: 5px;
	margin-left: 5px;
}
.mx-10 {
	margin-right: 10px;
	margin-left: 10px;
}
.mx-15 {
	margin-right: 15px !important;
	margin-left: 15px !important;
}
.mx-30 {
	margin-right: 30px !important;
	margin-left: 30px !important;
}
.mx-60 {
	margin-right: 60px !important;
	margin-left: 60px !important;
}
.y-scroll {
	overflow-y: scroll !important;
}
.x-scroll {
	overflow-x: scroll !important;
}
.scroll {
	overflow: scroll !important;
}
.center {
	margin: 0 auto;
	text-align: center !important;
	justify-content: center;
}
.right {
	margin-right: 0 !important;
	text-align: right !important;
	justify-content: flex-end !important;
}
.left {
	margin-left: 0 !important;
	text-align: left !important;
	justify-content: flex-start !important;
}
.is-dark {
	color: var(--main-text);
}
.is-red {
	color: red;
}
.is-green {
	color: #44C568;
}
.is-light {
	color: var(--secondary-text);
}
.is-white {
	color: var(--card-bg);
}
.is-orange {
	color: var(--link);
}
.is-blue {
	color: var(--blueLink);
}
.is-bold {
	font-weight: bold;
}
.is-normal {
	font-weight: normal !important;
}
.orange-btn {
	background-color: var(--orange-btn);
	color: #FFFFFF;
}
.orange-btn:hover {
	background-color: var(--orange-btn-hover);
	color: #FFFFFF;
}
.blue-btn {
	background-color: var(--blue-btn);
	color: #FFFFFF;
}
.blue-btn:hover {
	background-color: var(--blue-btn-hover);
}
.black-btn {
	background-color: var(--secondary-btn-bg);
	color: var(--secondary-btn-text)
}
.white-btn {
	background-color: var(--secondary-btn-text);
	color: var(--secondary-text);
	border: 1px solid rgba(39, 39, 39, 0.1) !important;
}
.select-red {
	background: rgba(227, 30, 40, 0.1) !important;
}
.select-red span, .select-red i {
	color: #E31E28 !important;
}
.select-orange {
	background: rgba(246, 146, 33, 0.15) !important;
	color: #F18F1F !important;
}
.select-orange span, .select-orange i {
	color: #F18F1F !important;
}
.inline {
	display: inline;
}
.full-height {
	height: 100% !important;
}
.full-width {
	width: 100% !important;
}
.half-width {
	width: 50% !important;
}
.one-third-width {
	width: 33% !important;
}
.two-third-width {
	width: 66% !important;
}
.one-fourth-width {
	width: 25% !important;
}
.one-fifth-width {
	width: 20% !important;
}
.screen-center {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0 !important;
}
.max-screen {
	display: flex;
	flex-direction: column;
	height: 100vh;
}
.max-screen__bottom, .remaining-width {
	flex: 1 1 auto;
}
.max-screen__bottom__2 {
	flex: 1 1 auto;
	height: 1px;
}
.cursor {
	cursor: pointer;
}
.flexer {
	display: flex;
}
.flexer-wrapper {
	flex-wrap: wrap;
}
.flexer-vcenter {
	align-items: center;
}
 
.flexer-spaced {
	justify-content: space-between;
}
.flexer-1 {
	flex: 0 0 8%;
}
.flexer-2 {
	flex: 0 0 19%;
}
.flexer-3 {
	flex: 0 0 25%;
}
.flexer-4 {
	flex: 0 0 32.3%;
}
.flexer-5 {
	flex: 0 0 40.6%;
}
.flexer-6 {
	flex: 0 0 49%;
}
.flexer-7 {
	flex: 0 0 57.3%;
}
.flexer-8 {
	flex: 0 0 65.6%;
}
.flexer-9 {
	flex: 0 0 75%;
}
.flexer-10 {
	flex: 0 0 79%;
}
.flexer-11 {
	flex: 0 0 90%;
}
.flexer-12 {
	flex: 0 0 98%;
}
.line-margin-0 {
	margin-bottom: 0em !important;
	margin-top: 0em !important;
}
.line-margin-xs {
	margin-bottom: 10px !important;
	margin-top: 10px !important;
}
.line-margin-s {
	margin-bottom: 0.5em;
	margin-top: 0.5em;
}
.side-margin-0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
.rating-text-setter {
	font-size: 24px;
	line-height: 29px;
	border-left: 1px solid lightgray;
	padding-left: 15px;
}
.breakable {
	word-break: break-word;
}
.fixed {
	position: fixed;
}
@media screen and (max-width: 1024px) {
	.flexer {
		flex-wrap: wrap;
	}
	.flexer-mobile {
		display: initial;
	}
	.center-mobile {
		margin: 0 auto !important;
		text-align: center !important;
		justify-content: center !important;
	}
	.full-width-mobile {
		width: 100% !important;
		padding: 0 !important;
	}
	.mobile-hidden {
		display: none !important;
	}
	.mobile-shown {
		display: block !important;
	}
	.Modal__container {
		width: 50% !important;
	}
	.Modal__full-screen-container {
		width: 100% !important;
	}
	.star-container {
		padding: 0 2px !important;
	}
	.widget-svg {
		width: 14px !important;
		height: 14px !important;
	}
	.rating-text-setter {
		font-size: 14px;
		line-height: 14px;
	}
	.dashboard-wrapper__card:first-child {
		margin: 15px !important;
	}
	.dashboard-wrapper__card {
		margin: 15px !important;
	}
	.dashboard-wrapper__card:last-child {
		margin: 15px !important;
	}
}
.hidden {
	display: none;
}
.shown {
	display: block;
}
.borderless {
	border: none !important;
}
.relative {
	position: relative;
}
.absolute {
	position: absolute;
}
.on-top {
	z-index: 1;
}
.no-border {
	border: none;
}
// Elements
.input-wrapper {
	position: relative;
}
.input-wrapper .icon {
	cursor: pointer;
}
.input-wrapper__icon-right {
	position: absolute;
	top: 0;
	right: 0;
	line-height: inherit;
}
.input-wrapper__icon-left {
	position: absolute;
	top: 0;
	left: 0;
}
.input-field {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0.4px;
	margin-top: 35px;
}
.input-field__label {
	font-size: 13px;
	color: var(--secondary-text);
}
.input-field__input {
	border: none;
	border-bottom: 1px solid var(--border);
	margin-top: 5px;
	width: 100%;
	font-size: 15px;
	color: var(--main-text);
	background: var(--card-bg);
	padding: 5px;
}
.checkbox {
	margin-right: 25px;
}
.radio-btn__label {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: lighter;
	font-size: 15px;
	line-height: 18px;
	color: var(--main-text);
	margin: .5rem 2%;
}
.radio-btn__input {
	margin-right: 10px;
}
.icon {
	color: var(--secondary-text);
	font-size: 20px;
}
.link, .blueLink {
	text-decoration: none;
	font-family: var(--main-font);
	font-style: normal;
	font-weight: lighter;
	font-size: 15px;
	line-height: 21px;
}
.link {
	color: var(--link);
}
.blueLink {
	color: var(--blueLink);
	font-weight: 400;
}
.button {
	border-radius: 5px;
	padding: 12px;
	border: none;
	font-family: var(--main-font);
	font-size: 15px;
	line-height: 18px;
	text-align: center;
	cursor: pointer;
	height: 50px;
	transition: ease all .3s;
}
.search-input {
	background: var(--main-bg);
	border-radius: 8px;
	border: none;
	padding: 10px 40px;
	width: 100%;
	box-sizing: border-box;
}
.search-input-2 {
	background: var(--card-bg);
	border: none;
	width: 100%;
	box-sizing: border-box;
}
.search-results {
	background: var(--main-bg);
	padding: 10px 40px;
	width: 100%;
	box-sizing: border-box;
	box-shadow: 1px 1px 0 0 var(--border);
}
.search-results:last-child {
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}
.search-results:hover {
	background-color: var(--card-bg);
}
.search-icon {
	padding: 10px;
	font-size: 15px;
}
.table {
	width: 100%;
}
.table-header th, .table-rows td {
	padding: 15px 0;
	border-bottom: 1px solid #EBEBEB;
}
.file-input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
/* Login styles
*****************************************/
// .login-content {
// }
// .login-content *{
//     box-sizing: border-box;
// }
// User Registration
.regist-card {
	background: var(--card-bg);
	border-radius: 5px;
	padding: 50px;
	margin: 20px auto 50px;
	// border: solid 20px #000;
}
// Wrapper
.profile-menu__checkbox {
	display: none;
}
.profile-menu__name {
	padding: 0 10px;
}
.profile-menu__image {
	vertical-align: middle;
	border-radius: 50%;
	background-color: var(--orange-btn);
	width: 40px;
	height: 40px;
}
.profile-menu__list {
	list-style: none;
	background-color: var(--card-bg);
	border-radius: 3px;
	position: absolute;
	right: 0;
	margin-right: 2%;
	margin-left: 2%;
	margin-top: 10px;
	width: 150px;
	padding: 0;
	z-index: 9;
	box-shadow: -2px -2px 10px 0 var(--shadow);
}
.profile-menu__list::before {
	content: ' ';
	position: absolute;
	left: auto;
	right: 7px;
	top: -7px;
	border: 10px solid;
	border-color: var(--card-bg) transparent transparent var(--card-bg);
	box-shadow: -2px -2px 2px -1px var(--shadow);
	transform: rotate(45deg);
}
.profile-menu__item {
	padding: 5px 10px;
	color: var(--main-text);
}
.profile-menu__item:hover {
	background-color: var(--main-bg);
	color: var(--link);
	border-radius: 3px;
}
.header-tabs {
	border-top: 1px solid var(--main-bg);
	box-shadow: 0 6px 16px -15px rgba(0, 0, 0, 0.295427);
}
.text-icon {
	font-size: 15px;
	line-height: 18px;
	color: var(--secondary-text);
}
.menu-tab {
	margin: 0 15px;
	padding: 0 10px;
	border-bottom: 2px solid var(--card-bg);
}
.menu-tab__case {
	border-bottom: 2px solid var(--main-bg);
}
.menu-tab:hover, .menu-tab__active {
	border-bottom: 2px solid var(--orange-btn);
}
.menu-tab:hover p, .menu-tab:hover i, .menu-tab__active p, .menu-tab__active i {
	color: var(--link);
}
.menu-tab i {
	margin-right: 10px;
}
.case-btn {
	background-color: var(--orange-btn);
	display: inline-block;
	padding: 0 30px;
	border: 2px solid var(--orange-btn);
}
.case-btn i {
	margin-right: 10px;
	color: white;
}
.case-btn:hover {
	background-color: white;
	border: 2px solid var(--orange-btn);
}
.case-btn:hover p, .case-btn:hover i {
	color: var(--link);
}
// Modal
.Modal {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.5);
}
.Modal__container {
	background-color: var(--card-bg);
	padding: 40px;
	width: 35%;
	border-radius: 5px;
	overflow: auto;
	max-height: 80%;
}
.Modal__full-screen-container {
	width: 100%;
	height: 92%;
	max-height: 92%;
	padding-left: 15%;
	padding-right: 15%;
	box-sizing: border-box;
	border-radius: 15px;
	position: fixed;
	bottom: 0;
}
.Modal__close {
	position: absolute;
	right: 10px;
	top: 5px;
	font-size: 20px;
}
// Content
.content-wrapper {
	background: var(--card-bg);
	margin: 30px auto;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
}
//// Profile
.simple-profile {
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	height: 100%;
}
.simple-profile__sections {
	padding: 20px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.simple-profile__sections:last-child {
	border-bottom: none;
}
.simple_profile__upper-sections {
	margin: 20px 0 0 0;
}
.icon-text {
	display: inline-flex;
	word-wrap: anywhere;
}
.simple-profile__picture {
	width: 50%;
	border-radius: 5px;
	background: var(--secondary-text);
}
.edit-profile__picture {
	width: 10%;
	border-radius: 5px;
	background: var(--secondary-text);
}
.social-icons {
	font-size: 25px;
	color: var(--orange-btn);
	margin: 5px;
	width: 36px;
}
.group-icon {
	font-size: 25px;
	background: var(--orange-btn);
	color: white;
	border-radius: 50%;
	margin: 0 5px 0 5px;
	text-align: center;
	padding: 10px;
}
.advanced-profile {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.exp-rev-section {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	padding: 20px;
}
.bubble {
	display: inline-block;
	position: relative;
	box-shadow: 2px 1px 4px 1px var(--shadow);
	padding: 0 15px;
	border-radius: 3px;
	width: -webkit-fill-available;
}
.bubble:before {
	content: ' ';
	position: absolute;
	left: 20px;
	right: auto;
	bottom: -7px;
	border: 7px solid;
	border-color: transparent var(--card-bg) var(--card-bg) transparent;
	box-shadow: 2px 2px 2px 0 var(--shadow);
	transform: rotate(45deg);
}
.reviewer-wrapper {
	margin: 20px 0;
}
.reviewer-wrapper__info {
	margin: 0 15px;
}
.rating-box {
	background-color: var(--main-bg);
	border-radius: 40px;
	padding: 5px 0;
}
.warning-message {
	background-color: var(--warning);
	padding: 15px;
}
// User Resources
.dashboard-section {
	padding: 20px 30px;
}
// Toggle
.toggle-wrapper {
	margin: 10px 0;
}
.toggle {
	margin: 2px 0;
}
.toggle-switch {
	position: relative;
	width: 65px;
	margin: 0 auto;

	&-checkbox {
		display: none;
	}

	&-label {
		display: block;
		overflow: hidden;
		cursor: pointer;
		border: 0 solid #bbb;
		border-radius: 20px;
		margin: 0;
	}

	&-inner {
		display: block;
		width: 200%;
		margin-left: -100%;
		transition: margin 0.3s ease-in 0s;
		font-family: var(--main-font);

		&:before,
		&:after {
			display: block;
			float: left;
			width: 50%;
			height: 25px;
			padding: 0;
			line-height: 25px;
			font-size: 14px;
			color: white;
			font-weight: bold;
			box-sizing: border-box;
		}

		&:before {
			content: "On";
			text-transform: uppercase;
			padding-left: 10px;
			background-color: var(--orange-btn);
			color: #fff;
		}
	}

	&-disabled {
		background-color: #ddd;
		cursor: not-allowed;

		&:before {
			background-color: #ddd;
			cursor: not-allowed;
		}
	}

	&-inner:after {
		content: "Off";
		text-transform: uppercase;
		padding-right: 10px;
		background-color: #bbb;
		color: #fff;
		text-align: right;
	}

	&-switch {
		display: block;
		width: 17px;
		height: 17px;
		margin: 4px;
		background: #fff;
		position: absolute;
		top: 0;
		bottom: 0;
		border: 0 solid #bbb;
		border-radius: 20px;
		transition: all 0.3s ease-in 0s;
	}

	&-checkbox:checked + &-label {
		.toggle-switch-inner {
			margin-left: 0;
		}

		.toggle-switch-switch {
			right: 0;
		}
	}
}
// Dashboard
.dashboard-wrapper {
	margin: 30px auto;
}
.dashboard-wrapper__card {
	background-color: var(--card-bg);
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	margin: 10px 0;
}
.dashboard-wrapper__card:first-child {
	margin: 10px 0 10px 0;
}
.dashboard-wrapper__card:last-child {
	margin: 10px 0 10px 0;
}
.dashboard-wrapper__content {
	padding: 20px 30px;
	box-sizing: border-box;
}
// Case generation
.step {
	border: 1px solid var(--step-menu);
	border-radius: 50%;
	text-align: center;
	vertical-align: middle;
	color: var(--step-menu);
	font-size: 24px;
	padding: 5px;
}
.step-active {
	background: var(--link);
	border: 1px solid var(--link);
	color: var(--link);
}
.step-past {
	background: var(--link);
	border: 1px solid var(--link);
	color: var(--card-bg);
}
.line {
	border-bottom: 2px dotted var(--step-menu);
	width: 83%;
}
.form-icon {
	margin-right: 30px;
	color: var(--secondary-text);
	font-size: 20px;
	margin-top: 10px;
}
div[class^="ReactFlagsSelect"] button {
	border: none;
	padding: 0px 0px 0px 0px;
	font-size: 10px;
	
}
div[class^="ReactFlagsSelect"] button[aria-expanded="true"]:after {
	border-bottom: 5px solid var(--main-text);
}
div[class^="ReactFlagsSelect"] button[aria-expanded="false"]:after {
	border-top: 5px solid var(--main-text);
}
div[class^="ReactFlagsSelect"] button span {
	color: var(--main-text);
	
}
.chip, .chip i {
	background: var(--link) !important;
	color: white !important;
	margin-bottom: 0;
}
// Case page
.case-sides {
	background: var(--card-bg);
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
	margin-top: 1px;
}
.case-links {
	padding: 10px 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.select-special {
	border-radius: 5px;
	margin-top: 0;
	border-bottom: none;
}
.upload-wrapper {
	border-radius: 3px;
	border: 1px dashed lightgray;
	padding: 10px;
	width: fit-content;
}
.upload-wrapper__icon {
	background: orange;
	padding: 6px;
	color: white;
	border-radius: 5px;
}
.comment-bubble {
	background: var(--card-bg);
	box-shadow: 0 3px 14px rgba(0, 0, 0, 0.15);
	border-radius: 60px;
}
.comment-bubble input {
	border: none;
	padding: 20px;
	border-radius: 60px;
}
// Chats
.chat-wrapper {
	background-color: var(--card-bg);
	border-radius: 8px;
	margin: 30px auto !important;
}
.chats-input {
	margin-top: 0 !important;
}
.chats-input input, .chats-input textarea {
	border-bottom: none !important;
	padding: 0 !important;
	margin: 0 !important;
	height: 100%;
}
.chats-multi .search-wrapper {
	border: none !important;
	padding: 0 !important;
}
.chats-multi .chip {
	margin-bottom: 0 !important;
}
.optionContainer {
	font-size: 15px;
	background-color: var(--card-bg);
}
.optionContainer span {
	color: var(--secondary-text);
}
.chats-max-screen {
	display: flex;
	flex-direction: column;
}
.chats__date-bubble {
	border-radius: 13px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.09);
	padding: 5px 20px;
	width: fit-content;
}
.chats__broadcast {
	background: rgba(246, 146, 33, 0.1);
	padding: 20px;
}
.chats__inactive {
	border-left: 4px solid var(--card-bg);
}
.chats__active, .chats__item:hover {
	background: var(--main-bg);
	border-left: 4px solid var(--orange-btn);
}
.chats__unread {
	background: var(--orange-btn);
	padding: 0 8px;
	border-radius: 8px;
	color: var(--secondary-btn-text);
	font-size: 10px;
	font-weight: bolder;
}
.start-thread {
	display: none;
}
.thread-wrapper:hover .start-thread {
	display: block !important;
}
.parent-thread {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	margin-bottom: 10px;
}
// home
.home {
	width: 55%;
}
.app-wrapper {
	background: var(--card-bg);
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	margin: 10% auto;
}
.subdomain-card {
	background: var(--card-bg);
	border-radius: 8px;
	padding: 30px 30px;
	margin: 40px auto;
	box-sizing: border-box;
	border: 1px solid rgba(0, 0, 0, 0.1);
}
.subdomains {
	padding: 0 30px;
	margin: 30px auto;
	justify-content: space-around;
}
.skeleton-animation {
	overflow: hidden;
	position: relative;
}
.skeleton-animation::after {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: '';
	position: absolute;
	transform: translateX(-100%);
	background: linear-gradient(
			90deg,
			rgba(249, 252, 255, 0) 0,
			rgba(249, 252, 255, 0.2) 20%,
			rgba(249, 252, 255, 0.5) 60%,
			rgba(249, 252, 255, 0)
	);
	animation: shimmer 2s infinite;
}

@keyframes shimmer {
	100% {
		transform: translateX(100%);
	}
}

// tootips

/* Wrapping */
.tooltip-wrapper {
	display: inline-block;
	position: relative;
}

.tooltip-custom{
 
	   
	 
	color: var(--secondary-text);
	background: var(--main-bg);
  
}

/* Absolute positioning */
.tooltip-tip {
	position: absolute;
	border-radius: 4px;
	left: 50%;
	transform: translateX(-50%);
	padding: 6px;
	color: var(--secondary-text);
	background: var(--main-bg);
	font-size: 14px;
	font-family: var(--main-font);
	box-shadow: 0px 4px 10px var(--shadow);
	line-height: 1;
	z-index: 100;
	white-space: nowrap;
}

.tooltip-tip.noBg {
	background: var(--card-bg);
}

/* Absolute positioning */
.tooltip-tip.top {
	top: calc(20px * -1);
}

/* Absolute positioning */
.tooltip-tip.right {
	left: calc(100% + 20px);
	top: 50%;
	transform: translateX(0) translateY(-50%);
}
.tooltip-tip.right-x {
	left: calc(80% + 20px);
	top: 80%;
	transform: translateX(0) translateY(-50%);
}

/* Absolute positioning */
.tooltip-tip.bottom {
	bottom: calc(20px * -1);
}
.tooltip-tip.bottom-x {
	bottom: calc(120px * -1);
	left: 75%;
}

/* Absolute positioning */
.tooltip-tip.left {
	left: auto;
	right: calc(100% + 20px);
	top: 50%;
	transform: translateX(0) translateY(-50%);
}

/* Cart number */
.fa-stack[data-count]:after{
  position:absolute;
  right:0%;
  top:0%;
  content: attr(data-count);
  font-size:40%;
  padding:.6em;
  border-radius:999px;
  line-height:.75em;
  color: var(--main-text);
  text-align:center;
  min-width:1em;
  font-weight:bold;
  background:var(--link);

}

.cart {
	background: var(--main-bg)
}


.phoneFields {
  display: flex !important;

  input {
    flex-grow: 2;
  }

  div {
    border-bottom: 1px solid #00000026;
  }
}
.profileImage {
	border-radius: 10%;
	background-color: #f19126;
	color: #fff;
	width: 6rem;
	height: 6rem;
	line-height: 6rem; 
	font-size: 3rem;
	margin-left: 5rem;
 }
 .EditProfileImage {
	 border-radius: 10%;
	 background-color: #f19126;
	 text-align: center;
	 color: #fff;
	 width: 3.1rem;
	 height: 3.1rem;
	 line-height: 3.1rem; 
	 font-size: 1.3rem;
  
  }
  .MenuProfileImage {
	 border-radius: 50%;
	 background-color: #f19126;
	 text-align: center;
	 color: #fff;
	 width: 2.5rem;
	 height: 2.5rem;
	 line-height: 2.5rem; 
	 font-size: 1rem;
  
  }
 .phone-style{
	outline: none;
	border: none;
	border-bottom-width: 7px;
	padding: 0.25rem 0.125rem;
	border-bottom-style: solid;
	background-color: transparent;
	transition: border 500ms ease-in-out;

	input {
		border: none;
	  }
	:focus {
		border-bottom-color: #f69221;
  }
 }

 // Security Setup
 multi-steps {
	justify-content: center;

	> div {
		flex: 0 0 18% !important;
	}

	& > div:last-child {
		flex: unset;
	}
}
