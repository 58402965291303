body,
html {
	padding: 0;
	margin: 0;
}
* {
	box-sizing: border-box;
}

.StripeElement--focus,
.StripeElement--complete {
	border: 1px solid #1660cf !important;
}

.StripeElement--invalid {
	border: 1px solid red !important;
}
.StripeElement {
	border: 1px solid #b2bcca;
	padding: 14px 20px 10px;
	border-radius: 4px;
	margin-top: -8px;
}

